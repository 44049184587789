var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("div", { staticClass: "tw-w-full tw-flex tw-justify-between" }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Taakstelling per Bureau")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _vm._l(_vm.organizations, function(organization, index) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: "planning-tab-" + index,
                          staticClass: "tab__filled",
                          class: { active: _vm.activeTab === organization.id },
                          attrs: { href: "#" + organization.id },
                          on: {
                            click: function($event) {
                              _vm.getPlanningStatistics(organization.id)
                              _vm.isLoading = true
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(organization.name) + " ")]
                      )
                    ]
                  }),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _vm._l(_vm.organizations, function(organization) {
                        return [
                          _c(
                            "v-tab-item",
                            {
                              key: "planning-tab-content-" + organization.name,
                              attrs: {
                                lazy: true,
                                transition: false,
                                "reverse-transition": false,
                                value: organization.id
                              }
                            },
                            [
                              _vm.activeTab === organization.id
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "elementPanel",
                                      attrs: { row: "", wrap: "" }
                                    },
                                    [
                                      _vm.isLoading
                                        ? _c("ALoader", {
                                            staticClass:
                                              "tw-flex tw-w-full tw-h-full tw-mt-32 tw-mb-64"
                                          })
                                        : _vm._e(),
                                      !_vm.isLoading
                                        ? [
                                            _c(
                                              "v-layout",
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "tw-flex tw-justify-between",
                                                    attrs: { xs12: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs4: "" } },
                                                      [
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            ref: "dialog",
                                                            refInFor: true,
                                                            attrs: {
                                                              "close-on-content-click": false,
                                                              lazy: "",
                                                              "nudge-right":
                                                                "130",
                                                              "full-width": "",
                                                              "max-width":
                                                                "290px",
                                                              "min-width":
                                                                "290px"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.isEditingDate,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.isEditingDate = $$v
                                                              },
                                                              expression:
                                                                "isEditingDate"
                                                            }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "input input-date",
                                                              attrs: {
                                                                slot:
                                                                  "activator",
                                                                "hide-details":
                                                                  "",
                                                                placeholder:
                                                                  "dd-mm-jjjj",
                                                                label: "Datum",
                                                                "error-messages":
                                                                  _vm.dateErrorMessage
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.formatDateFromTextField(
                                                                    $event
                                                                  )
                                                                }
                                                              },
                                                              slot: "activator",
                                                              model: {
                                                                value:
                                                                  _vm.dateFormatted,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.dateFormatted = $$v
                                                                },
                                                                expression:
                                                                  "dateFormatted"
                                                              }
                                                            }),
                                                            _c(
                                                              "v-date-picker",
                                                              {
                                                                ref: "picker",
                                                                refInFor: true,
                                                                attrs: {
                                                                  locale:
                                                                    "nl-nl",
                                                                  min:
                                                                    "1910-01-01",
                                                                  color:
                                                                    "#837f16",
                                                                  "first-day-of-week":
                                                                    "1"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.isEditingDate = false
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.date = $$v
                                                                  },
                                                                  expression:
                                                                    "date"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "tw-flex"
                                                      },
                                                      [
                                                        _c(
                                                          "AButton",
                                                          {
                                                            staticClass:
                                                              "tw-self-center tw-bg-transparent tw-border-gray-500 tw-text-gray-500 tw-mr-16",
                                                            attrs: {
                                                              "prepend-inner-icon":
                                                                "toc"
                                                            },
                                                            nativeOn: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showLogs(
                                                                  $event
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [_vm._v(" Logboek ")]
                                                        ),
                                                        _c(
                                                          "AButton",
                                                          {
                                                            staticClass:
                                                              "tw-self-center",
                                                            class: _vm.hasChanges
                                                              ? "tw-bg-secondary-400 tw-text-white"
                                                              : "tw-border-gray-500 tw-text-gray-500 tw-bg-white",
                                                            attrs: {
                                                              "prepend-inner-icon":
                                                                "save",
                                                              loading:
                                                                _vm.isUpdating,
                                                              disabled: !_vm.hasChanges
                                                            },
                                                            nativeOn: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.save()
                                                              }
                                                            }
                                                          },
                                                          [_vm._v(" Opslaan ")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "overflow-auto" },
                                              [
                                                _c(
                                                  "table",
                                                  {
                                                    staticClass:
                                                      "mb-5 item__list item__list--table table--no-collapse"
                                                  },
                                                  [
                                                    _c("thead", [
                                                      _c(
                                                        "tr",
                                                        {
                                                          staticClass:
                                                            "label header__row",
                                                          attrs: {
                                                            wrap: "",
                                                            row: "",
                                                            "align-center": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "reportType-header bordered",
                                                              attrs: {
                                                                colspan: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "text-right",
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Taakstelling"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "reportType-header"
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "text-right",
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Gepland"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "tr",
                                                        {
                                                          staticClass:
                                                            "label header__row",
                                                          attrs: {
                                                            wrap: "",
                                                            row: "",
                                                            "align-center": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "font-weight-regular bordered",
                                                              attrs: {
                                                                colspan: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "text-right font-weight-regular",
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "# afspraken"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "font-weight-regular"
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "text-right font-weight-regular",
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "# afspraken"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _c(
                                                      "tbody",
                                                      {
                                                        staticClass:
                                                          "tw-text-gray-600"
                                                      },
                                                      _vm._l(
                                                        _vm.reportTypes,
                                                        function(
                                                          reportType,
                                                          i
                                                        ) {
                                                          return _c(
                                                            "tr",
                                                            {
                                                              key:
                                                                "report-" +
                                                                i +
                                                                "-taakstelling",
                                                              staticClass:
                                                                "label"
                                                            },
                                                            [
                                                              _c("td", [
                                                                i === 0
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "parseWeek"
                                                                            )(
                                                                              _vm.date
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ])
                                                                  : _vm._e()
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _c(
                                                                    "v-layout",
                                                                    {
                                                                      attrs: {
                                                                        row: "",
                                                                        wrap: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          attrs: {
                                                                            xs12:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              reportType
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "bordered"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-layout",
                                                                    {
                                                                      attrs: {
                                                                        row: "",
                                                                        wrap: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            xs12:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              staticClass:
                                                                                "taakstellingInput taakstelling-text",
                                                                              class: _vm.hasChanged(
                                                                                reportType
                                                                              )
                                                                                ? "v-input--is-focused primary--text"
                                                                                : "",
                                                                              attrs: {
                                                                                "hide-details": true,
                                                                                placeholder:
                                                                                  "taakstelling",
                                                                                type:
                                                                                  "number",
                                                                                disabled: !_vm
                                                                                  .$store
                                                                                  .state
                                                                                  .isServiceOrganization
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .summaryPlanningStatistics
                                                                                    .target[
                                                                                    reportType
                                                                                  ],
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .summaryPlanningStatistics
                                                                                      .target,
                                                                                    reportType,
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "summaryPlanningStatistics.target[reportType]"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _c(
                                                                    "v-layout",
                                                                    {
                                                                      attrs: {
                                                                        row: "",
                                                                        wrap: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          staticClass:
                                                                            "text-right body-2",
                                                                          attrs: {
                                                                            xs12:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .summaryPlanningStatistics
                                                                                  .data[
                                                                                  reportType
                                                                                ]
                                                                                  .planned_reports
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "overflow-auto" },
                                              [
                                                _c(
                                                  "table",
                                                  {
                                                    staticClass:
                                                      "mb-5 item__list item__list--table table--no-collapse"
                                                  },
                                                  [
                                                    _c("thead", [
                                                      _c(
                                                        "tr",
                                                        {
                                                          staticClass:
                                                            "label header__row",
                                                          attrs: {
                                                            wrap: "",
                                                            row: "",
                                                            "align-center": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "reportType-header bordered",
                                                              attrs: {
                                                                colspan: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "text-right",
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Taakstelling"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "reportType-header"
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "text-right",
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Gepland"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "tr",
                                                        {
                                                          staticClass:
                                                            "label header__row",
                                                          attrs: {
                                                            wrap: "",
                                                            row: "",
                                                            "align-center": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "font-weight-regular bordered",
                                                              attrs: {
                                                                colspan: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "text-right font-weight-regular",
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "# afspraken"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "font-weight-regular"
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "text-right font-weight-regular",
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "# afspraken"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _c(
                                                      "tbody",
                                                      {
                                                        staticClass:
                                                          "tw-text-gray-600"
                                                      },
                                                      [
                                                        _vm._l(
                                                          _vm.regulierBreakdown,
                                                          function(
                                                            reportType,
                                                            i
                                                          ) {
                                                            return _c(
                                                              "tr",
                                                              {
                                                                key:
                                                                  "report-" +
                                                                  i +
                                                                  "-taakstelling",
                                                                staticClass:
                                                                  "label"
                                                              },
                                                              [
                                                                _c("td", [
                                                                  i === 0
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Breakdown Regulier"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]),
                                                                _c(
                                                                  "td",
                                                                  [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        attrs: {
                                                                          row:
                                                                            "",
                                                                          wrap:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            staticClass:
                                                                              "font-weight-bold",
                                                                            attrs: {
                                                                              xs12:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            reportType ===
                                                                            "unknown"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "Zonder deskundige"
                                                                                  )
                                                                                ]
                                                                              : reportType ===
                                                                                "without_level"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "Zonder Niveau"
                                                                                  )
                                                                                ]
                                                                              : [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .userLevelLabels[
                                                                                        reportType
                                                                                      ]
                                                                                    )
                                                                                  )
                                                                                ]
                                                                          ],
                                                                          2
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "bordered"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        attrs: {
                                                                          row:
                                                                            "",
                                                                          wrap:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            attrs: {
                                                                              xs12:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            ![
                                                                              "unknown",
                                                                              "without_level"
                                                                            ].includes(
                                                                              reportType
                                                                            )
                                                                              ? _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    staticClass:
                                                                                      "taakstellingInput taakstelling-text",
                                                                                    class: _vm.hasChanged(
                                                                                      reportType
                                                                                    )
                                                                                      ? "v-input--is-focused primary--text"
                                                                                      : "",
                                                                                    attrs: {
                                                                                      "hide-details": true,
                                                                                      placeholder:
                                                                                        "taakstelling",
                                                                                      type:
                                                                                        "number",
                                                                                      disabled: !_vm
                                                                                        .$store
                                                                                        .state
                                                                                        .isServiceOrganization
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .summaryPlanningStatistics
                                                                                          .target[
                                                                                          reportType
                                                                                        ],
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .summaryPlanningStatistics
                                                                                            .target,
                                                                                          reportType,
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "summaryPlanningStatistics.target[reportType]"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              : _vm._e()
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "td",
                                                                  [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        attrs: {
                                                                          row:
                                                                            "",
                                                                          wrap:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            staticClass:
                                                                              "text-right body-2",
                                                                            attrs: {
                                                                              xs12:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .summaryPlanningStatistics
                                                                                    .data[
                                                                                    reportType
                                                                                  ]
                                                                                    .planned_reports
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _c(
                                                          "tr",
                                                          {
                                                            staticClass: "label"
                                                          },
                                                          [
                                                            _c("td"),
                                                            _c("td", [
                                                              _vm._v("Totaal")
                                                            ]),
                                                            _c("td"),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-right"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.totalBreakdownRegulier
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "table",
                                              {
                                                staticClass:
                                                  "mb-5 item__list item__list--table table--no-collapse"
                                              },
                                              [
                                                _c("thead", [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "label header__row",
                                                      attrs: {
                                                        wrap: "",
                                                        row: "",
                                                        "align-center": ""
                                                      }
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            organization.name
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v("Datum")
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-right"
                                                        },
                                                        [_vm._v("Opgegeven")]
                                                      )
                                                    ]
                                                  )
                                                ]),
                                                _vm.weekPlanningStatistics
                                                  ? _c(
                                                      "tbody",
                                                      [
                                                        _vm._l(
                                                          _vm.weekPlanningStatistics,
                                                          function(day, index) {
                                                            return _c(
                                                              "tr",
                                                              {
                                                                key:
                                                                  "day-" +
                                                                  index,
                                                                staticClass:
                                                                  "list__item"
                                                              },
                                                              [
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "weekdayFormat"
                                                                      )(
                                                                        day.name
                                                                      )
                                                                    )
                                                                  )
                                                                ]),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dateFormat"
                                                                      )(
                                                                        day.name
                                                                      )
                                                                    )
                                                                  )
                                                                ]),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "text-right"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        day
                                                                          .data[
                                                                          "max_appointments"
                                                                        ]
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _c(
                                                          "tr",
                                                          {
                                                            staticClass:
                                                              "list__item"
                                                          },
                                                          [
                                                            _c("td", [
                                                              _vm._v("Totaal")
                                                            ]),
                                                            _c("td"),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-right"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.totalMaxAppointment.toFixed(
                                                                      0
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }