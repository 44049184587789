import ADialogLayout from '@/components/ADialogLayout/ADialogLayout';
import DataTable, { TableOptions, TableVisibility } from '@/components/data-table/DataTable';
import { Organization } from '@/models/Organization';
import { PlanningTargetLog, PlanningTargetLogInclude } from '@/models/PlanningTargetLog';
import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { Level, userLevelLabels } from '@/models/User';

@Component<PlanningStatisticsLogsDialog>({})
export default class PlanningStatisticsLogsDialog extends Vue {
  // #region @Props

  @Prop()
  protected organizationId!: string;

  // #endregion

  // #region @Refs

  @Ref()
  protected dialog!: ADialogLayout;

  @Ref()
  protected table!: DataTable;
  // #endregion

  // #region Class properties

  public $pageTitle = 'Logboek';

  protected reportTypes: SelectItem[] = [
    { name: 'Regulier', value: 'regulier' },
    { name: 'Agro', value: 'agro' },
    { name: 'Monument', value: 'monument' },
    { name: 'MKB', value: 'mkb' },
    { name: 'Opnemersvariant', value: 'opnemersvariant' },
    { name: 'Nulmeting', value: 'nulmeting' },
  ];

  protected regulierBreakdown: SelectItem[] = [
    { name: userLevelLabels[Level.SCHADEOPNEMER], value: Level.SCHADEOPNEMER },
    { name: userLevelLabels[Level.PROCESEXPERT], value: Level.PROCESEXPERT },
    { name: userLevelLabels[Level.JUNIOR_DESKUNDIGE], value: Level.JUNIOR_DESKUNDIGE },
    { name: userLevelLabels[Level.MEDIOR_DESKUNDIGE], value: Level.MEDIOR_DESKUNDIGE },
    { name: userLevelLabels[Level.SENIOR_DESKUNDIGE], value: Level.SENIOR_DESKUNDIGE },
    { name: userLevelLabels[Level.SPECIAL_DESKUNDIGE], value: Level.SPECIAL_DESKUNDIGE },
    { name: userLevelLabels[Level.PROJECT_COORDINATOR], value: Level.PROJECT_COORDINATOR },
  ];

  protected changedColumns: SelectItem[] = [...this.reportTypes, ...this.regulierBreakdown];

  protected tableFilters: TableFilter = {
    organization: this.organizationId,
    target_date: '',
  };

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
    search: false,
  }

  // #endregion

  // #region Class methods

  protected refreshDataTable(): void {
    this.table?.refresh();
  }

  protected close() {
    this.$emit('input', false);
  }

  protected capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  // #endregion

  // #region Getters & Setters

  protected get tableOptions(): TableOptions {
    return {
      model: new PlanningTargetLog().include([PlanningTargetLogInclude.ORGANIZATION]).filter(this.dataTableFilters),
      name: {
        singular: 'Gebruiker',
        plural: 'Gebruikersbeheer',
      },
      headers: [
        {
          text: 'Bureau',
          value: 'organization',
          transform: (organization: Organization) => (organization ? organization.name : '-'),
        },
        {
          text: 'Jaar (week)',
          value: 'target_date',
          transform: (date: string) => {
            const dateObj = DateTime.fromSQL(date);

            return dateObj ? `${dateObj.year} (${dateObj.weekNumber})` : '-';
          },
        },
        {
          text: 'Competentie / Niveau',
          value: 'changed_column',
          transform: (column: string) => (column ? this.capitalizeFirstLetter(column) : '-'),
        },
        {
          text: 'Wijziging',
          value: 'text',
        },
      ],
    };
  }

  private get dataTableFilters() {
    return this.tableFilters;
  }
  // #endregion
}

interface TableFilter {
  organization?: string;
  target_date?: string;
  changed_column?: string | string[];
}

interface SelectItem {
  name?: string;
  value?: string | number;
}
