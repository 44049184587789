var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ADialogLayout",
    {
      ref: "dialog",
      attrs: { title: "Logboek", hasFooter: false, hasConfirm: false }
    },
    [
      _c(
        "AStatefulFormForFilters",
        [
          _c("AAutocompleteInput", {
            staticClass: "levelFilter with-border",
            attrs: {
              clearable: "",
              "hide-details": "",
              "item-text": "name",
              "item-value": "value",
              items: _vm.changedColumns,
              label: "Competentie / Niveau",
              multiple: ""
            },
            model: {
              value: _vm.tableFilters.changed_column,
              callback: function($$v) {
                _vm.$set(_vm.tableFilters, "changed_column", $$v)
              },
              expression: "tableFilters.changed_column"
            }
          }),
          _c("DateFilter", {
            attrs: { label: "Datum", clearable: "" },
            model: {
              value: _vm.tableFilters.target_date,
              callback: function($$v) {
                _vm.$set(_vm.tableFilters, "target_date", $$v)
              },
              expression: "tableFilters.target_date"
            }
          })
        ],
        1
      ),
      _c("DataTable", {
        ref: "table",
        attrs: { options: _vm.tableOptions, visibility: _vm.visibility },
        on: { "data-update": _vm.refreshDataTable }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }