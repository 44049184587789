import { Component, Vue, Watch } from 'vue-property-decorator';
import { Organization } from '@/models/Organization';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { DateTime, Settings } from 'luxon';
import { firstDayOfWeek, setFormattedDatePickerValue, isValidDate, dateErrorMessage, formatDate } from '@/support/String';
import { cloneDeep } from 'lodash';
import Client from '@/support/Client';
import { Setting } from '@/models/Setting';
import { PlanningTarget } from '@/models/PlanningTarget';
import { Level, userLevelLabels } from '@/models/User';
import { ADialog } from '@/support/ADialog';
import PlanningStatisticsLogsDialog from '@/components/dialog/PlanningStatisticsLogsDialog/PlanningStatisticsLogsDialog.vue';

@Component<PlanningStatistics>({
  filters: {
    dateFormat: (date: string) => {
      if (! date) {
        return '-';
      }

      return formatDate(date, 'cccc dd-LL-yyyy');
    },
    parseWeek: (date: string) => {
      if (! date) {
        return '-';
      }

      return `Week ${formatDate(date, 'W')}`;
    },
  },
})
export default class PlanningStatistics extends Vue {
  public $pageTitle = 'Planning Statistieken';

  protected organizations: Organization[] | null = null;

  protected resourceEvents: any[] = [];

  protected statistics: any[] = [];

  protected currentWeek: DateTime[] = [];

  protected isEditingDate = false;

  protected isUpdating = false;

  protected isLoading = true;

  protected date = '';

  protected activeTab = '';

  protected callCounter = 0;

  protected reportTypes: string[] = [
    'regulier',
    'agro',
    'monument',
    'mkb',
    'opnemersvariant',
    'nulmeting',
  ];

  protected regulierBreakdown: string[] = [
    Level.SCHADEOPNEMER,
    Level.PROCESEXPERT,
    Level.JUNIOR_DESKUNDIGE,
    Level.MEDIOR_DESKUNDIGE,
    Level.SENIOR_DESKUNDIGE,
    Level.SPECIAL_DESKUNDIGE,
    Level.PROJECT_COORDINATOR,
    'without_level',
    'unknown',
  ];

  protected summaryPlanningStatistics: any = {};

  protected initialSummaryPlanningStatistics: any = {};

  protected weekPlanningStatistics: any[] = [];

  protected mounted() {
    this.getSettings();
    Settings.defaultLocale = 'nl';
  }

  protected save(): void {
    this.updateSummaryPlanningStatistics();
  }

  protected showLogs(): void {
    ADialog.open(PlanningStatisticsLogsDialog, {
      props: {
        organizationId: this.activeTab,
        wide: true,
      },
    });
  }

  protected hasChanged(reportType: string): boolean {
    const currentValue = Number(this.summaryPlanningStatistics.target[reportType]);
    const initialValue = Number(this.initialSummaryPlanningStatistics.target[reportType]);

    if (currentValue !== initialValue) {
      return true;
    }

    return false;
  }

  protected async updateSummaryPlanningStatistics() {
    if (! this.summaryPlanningStatistics || ! this.summaryPlanningStatistics.target) { return; }

    this.isUpdating = true;

    const payload = this.getPayload();

    try {
      await new PlanningTarget(this.summaryPlanningStatistics.target)
        .update(payload);

      if (this.organizations) {
        this.getPlanningStatistics(this.activeTab as string);
      }
    } catch (error) {
      ErrorHandler.network(error);
    } finally {
      this.isUpdating = false;
    }
  }

  protected getPayload(): any {
    const payload: any = {};

    this.reportTypes.forEach((type: string) => {
      if (this.hasChanged(type)) {
        payload[type] = this.summaryPlanningStatistics.target[type];
      }
    });

    this.regulierBreakdown.forEach((rbType: string) => {
      if (this.hasChanged(rbType)) {
        payload[rbType] = this.summaryPlanningStatistics.target[rbType];
      }
    });

    return payload;
  }

  protected getSettings() {
    new Setting()
      .all()
      .then((settings: Setting[]) => {
        const start = settings.find((currentSetting: Setting) => currentSetting.key === 'planning_active_week');

        if (! start || ! start.value) {
          return;
        }

        this.date = formatDate(start.value as string, 'yyyy-LL-dd');
        if (this.$route.query.date) {
          this.date = this.$route.query.date as string;
        }
        this.generateCurrentWeek();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getOrganizations() {
    new Organization()
      .getAllExperts()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;

        if (organizations && organizations.length) {
          this.activeTab = this.organizations[0].id as string;
          this.getPlanningStatistics(this.organizations[0].id as string);
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  private generateCurrentWeek() {
    if (! this.date) {
      return;
    }

    this.currentWeek = [];
    const weekStart = firstDayOfWeek(DateTime.fromFormat(this.date, 'yyyy-LL-dd'));
    for (let i = 0; i <= 6; i += 1) {
      const date = weekStart.plus({ days: i });
      this.currentWeek.push(date);
    }

    if (this.$store.state.isServiceOrganization) {
      this.getOrganizations();
    } else {
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
      this.activeTab = this.organizations[0].id as string;
      this.getPlanningStatistics(this.organizations[0].id as string);
    }
  }

  protected async getPlanningStatistics(id: string) {
    this.isLoading = true;
    await this.getSummaryPlanningStatistics(id);
    await this.getWeekPlanningStatistics(id);
    this.isLoading = false;
  }

  protected dayLabel(day: DateTime) {
    if (! day) {
      return '';
    }

    return day.toFormat('cccc');
  }

  protected async getSummaryPlanningStatistics(id: string) {
    if (! id) {
      return;
    }

    const payload = {
      date: this.date,
      mode: 'summary',
    };

    await Client('get', '/dmz/planning/statistics', payload, false, id)
      .then((response: AxiosResponse) => {
        this.summaryPlanningStatistics = response.data;
        this.initialSummaryPlanningStatistics = cloneDeep(this.summaryPlanningStatistics);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getWeekPlanningStatistics(id: string) {
    if (! id) {
      return;
    }

    const payload = {
      date: this.date,
    };

    await Client('get', '/dmz/planning/statistics', payload, false, id)
      .then((response: AxiosResponse) => {
        this.weekPlanningStatistics = response.data;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected dateFormatted: string | null = null;

  protected dateErrorMessage = '';

  protected formatDateDatePicker() {
    if (this.date) {
      this.dateFormatted = setFormattedDatePickerValue(this.date, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.dateFormatted) ? dateErrorMessage : '';
    }
  }

  protected formatDateFromTextField(value: string) {
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.date = setFormattedDatePickerValue(value);
  }

  protected get hasChanges(): boolean {
    return [...this.reportTypes, ...this.regulierBreakdown].some((type) => {
      if (['unknown', 'without_level'].includes(type)) {
        return false;
      }

      return this.hasChanged(type);
    });
  }

  protected get totalMaxAppointment() {
    let total = 0;
    this.weekPlanningStatistics.forEach((day: any) => {
      total += day.data.max_appointments;
    });
    return total;
  }

  protected get totalPlanned() {
    let total = 0;
    this.weekPlanningStatistics.forEach((day: any) => {
      total += Math.round(day.data.regulier.planned_reports);
      total += Math.round(day.data.specials.planned_reports);
      total += Math.round(day.data.opnemersvariant.planned_reports);
    });
    return total;
  }

  protected get totalAvailable() {
    let total = 0;
    this.weekPlanningStatistics.forEach((day: any) => {
      total += Math.round(day.data.available_hours / 4);
    });
    return total;
  }

  protected get totalDifference() {
    return this.totalAvailable - this.totalPlanned;
  }

  protected get totalPlannedHours() {
    let total = 0;
    this.weekPlanningStatistics.forEach((day: any) => {
      total += Math.round(day.data.regulier.planned_hours);
      total += Math.round(day.data.specials.planned_hours);
      total += Math.round(day.data.opnemersvariant.planned_hours);
    });
    return total;
  }

  protected get totalAvailableHours() {
    let total = 0;
    this.weekPlanningStatistics.forEach((day: any) => {
      total += Math.round(day.data.available_hours);
    });
    return total;
  }

  protected get totalDifferenceHours() {
    return this.totalAvailableHours - this.totalPlannedHours;
  }

  protected get totalAppointments() {
    return Object.keys(this.summaryPlanningStatistics.data) // all available keys
      .filter((key: string) => this.reportTypes.includes(key)) // filter to only reportType keys
      .reduce((accumulator, key) => accumulator + (this.summaryPlanningStatistics.data?.[key]?.planned_reports || 0), 0); // sum them up
  }

  protected get totalBreakdownRegulier() {
    return Object.keys(this.summaryPlanningStatistics.data) // all available keys
      .filter((key: string) => this.regulierBreakdown.includes(key)) // filter to only regulierBreakdown keys
      .reduce((accumulator, key) => accumulator + (this.summaryPlanningStatistics.data?.[key]?.planned_reports || 0), 0); // sum them up
  }

  protected get userLevelLabels() {
    return userLevelLabels;
  }

  @Watch('date')
  protected DateChanged() {
    this.formatDateDatePicker();
  }

  @Watch('isEditingDate')
  protected isEditingFromChanged() {
    if (! this.isEditingDate) {
      this.formatDateDatePicker();

      // rpc
      this.getPlanningStatistics(this.activeTab as string);
    }
  }

  protected destroyed() {
    this.$store.dispatch('updateSelectedPlanningDate', '');
  }
}
